import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store';


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


createApp(App).use(router).use(store).mount('#app')
