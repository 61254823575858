export default [
  {
    path: "/",
    name: "index page",
    meta: {
      title: "index page",
    },
    component: () => import("../views/index"),
  },
  {
    path: "/alumni",
    name: "alumni page",
    meta: {
      title: "alumni page",
    },
    component: () => import("../views/alumni"),
  },
  {
    path: "/impact",
    name: "impact page",
    meta: {
      title: "impact page",
    },
    component: () => import("../views/impact"),
  },
  {
    path: "/blogDetail",
    name: "blog detail",
    meta: {
      title: "blog detail",
    },
    component: () => import("../views/blogDetail"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "About",
    },
    component: () => import("../views/about"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact",
    },
    component: () => import("../views/contact"),
  },
  {
    path: "/rollOfhonor",
    name: "rollOfhonor",
    meta: {
      title: "Alumni",
    },
    component: () => import("../views/rollOfhonor"),
  },
  {
    path: "/giving/:content?",
    name: "giving",
    meta: {
      title: "Giving",
    },
    component: () => import("../views/giving"),
  },
  {
    path: "/opportunities/:content?",
    name: "opportunities",
    meta: {
      title: "opportunities",
    },
    component: () => import("../views/opportunities"),
  },
  {
    path: "/crowdfunding",
    name: "crowdfunding",
    meta: {
      title: "crowdfunding",
    },
    component: () => import("../views/crowdfunding"),
  },
  {
    path: "/advancement",
    name: "advancement",
    meta: {
      title: "advancement",
    },
    component: () => import("../views/advancement"),
  }
];
