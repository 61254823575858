import { createWebHistory, createRouter } from "vue-router";
// mport axios from 'axios';
import routes from './routes';
import store from '../store';

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

  store.dispatch("closeMobileMenu");
  next();
});


export default router;