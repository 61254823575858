// store/index.js
import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      showMobileMenu: false,
    };
  },
  mutations: {
    toggleShowMobileMenu(state) {
      state.showMobileMenu = !state.showMobileMenu;
    },
    closeMobileMenu(state) {
      state.showMobileMenu = false;
    },
  },
  actions: {
    toggleShowMobileMenu(context) {
      context.commit("toggleShowMobileMenu");
    },
    closeMobileMenu(context) {
      context.commit("closeMobileMenu");
    },
  },
  getters: {
    showMobileMenu(state) {
      return state.showMobileMenu;
    },
  },
});

export default store;
